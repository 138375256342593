body, html {
  height: 100%;
  margin: 0;
}

#root{
  height: 100%;
  background-image: url(../img/bg.jpeg);
  background-position: 50% 80%;
  background-repeat: no-repeat;
  background-size: cover; 
}