.info-row {
  .image-column {
    vertical-align: middle;
    display: table-cell;

    .image-column-container {
      display: flex;
      max-height: 120px;
      max-width: 120px;
      float: right;
      img {
        margin: auto;
        max-height: 120px;
        max-width: 120px;
        height: auto;
        width: auto;
      }
    }

  }
 
  .info-name {
    font-weight: bold;
  }

}

