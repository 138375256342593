#info-display {
  margin: 0.5em 0 1em 0;
  max-width: 800px;

  #caption {
    text-align: center;
    border-top: 1px solid #dee2e6;
    border-radius: 5px 5px;
  }
}

#selected-item {
  display: flex;

  #selected-name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
  }

  #search-image-container {
    display: flex;
    height: 170px;
    width: 170px;
    margin: 10px;
    padding: 10px;

    img {
      margin: auto;
      max-height: 150px;
      max-width: 150px;
      height: auto;
      width: auto;
    }
  }
}